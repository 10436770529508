import { initializeApp } from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage'
import {getFunctions} from 'firebase/functions'

const firebaseConfig = {
  apiKey: "AIzaSyBHedJoV5PzJQL3T-HrqZLo9pcduULk38s",
  authDomain: "otusplus-260e1.firebaseapp.com",
  databaseURL: "https://otusplus-260e1.firebaseio.com",
  projectId: "otusplus-260e1",
  storageBucket: "otusplus-260e1.appspot.com",
  messagingSenderId: "16994645141",
  appId: "1:16994645141:web:64305199eab266d166979c",
  measurementId: "G-LQB65ZNE5M"
};

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);
  export const firestore = getFirestore(app);
  export const storage = getStorage(app)
  export const functions = getFunctions(app)