/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceUser from "../service/ServiceUser";
import * as ServiceSerial from "../service/ServiceSerial";

class SerialList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seriallist : [],
            contentCount : 0,
            contentPerPage : 10,
            pageNow : 1,
            lastPage : 1,
        }
        this.handlerOnBtnActivate = this.handlerOnBtnActivate.bind(this);
        this.handlerOnBtnReset = this.handlerOnBtnReset.bind(this);
        this.handlerOnBtnGetSerials = this.handlerOnBtnGetSerials.bind(this);
        this.handlerOnBtnDel = this.handlerOnBtnDel.bind(this);
        this.innerConstructor();
    }

    handlerOnBtnActivate(id) {
        console.log("function Activate called"+id);
    }

    handlerOnBtnReset(id) {
      if(window.confirm("This action is not revoked. are you sure?")) {
        ServiceSerial.svcResetSerial(id)
        .then(response => {
           window.alert("Serial number has been reset!");
        })
      }
    }

    handlerOnBtnGetSerials(id) {
      return;
        ServiceSerial.svcGetSerials(0, 0)
        .then(response => {
            var data = ["code,serial\n"];
            for(var i=0; i<response.length; i++) {
                data.push([response[i].gencode+","+response[i].serial+"\n"]);
            }

            var a = document.createElement('a');
            var blob = new Blob(data, {'type':'text/csv'});
            a.href = window.URL.createObjectURL(blob);
            a.download = "serials.csv";
            a.click();
            window.URL.revokeObjectURL(a.href);
        })
        .catch(error => {
            console.log("not ok "+error);
        });
    }

    handlerOnBtnDel(id) {
      return;
        if(window.confirm("this action is not revoked. are you sure?")) {
            ServiceUser.svcDelUser(id)
            .then(response => {
                console.log(response);
                console.log("function Del called"+id);
                this.innerConstructor();
            })
            .catch(error => {
                console.log("UserList.js : handlerOnBtnDel : fetch list error "+error);
            });
        }
    }

    handlerOnPage(page) {
        this.setState({pageNow:page});
        ServiceSerial.svcGetSerials(page,this.state.contentPerPage, this.props.filter)
        .then(response => {
            console.log("Response:"+response);
            this.setState({seriallist: response});
        })
        .catch(error => {
            console.log("UserList.js : innerConstructor : fetch list error "+error);
        });
    }
    setUsedSerials(){

    }

  innerConstructor() {
    console.log("Filter: "+this.props.filter)
      ServiceSerial.svcSerialCount(this.props.filter)
      .then(response => {
        this.setState({contentCount:response.count});
        this.setState({lastPage:Math.ceil(response.count/this.state.contentPerPage)});

      ServiceSerial.svcGetSerials(this.state.pageNow,this.state.contentPerPage,this.props.filter)
      .then(response => {
            this.setState({seriallist: response});
        })
        .catch(error => {
            console.log("SerialList.js : innerConstructor : fetch list error "+error);
        });
      }).catch(error => {
        console.log("SerialList.js : innerConstructor : fetch list error "+error);
      });
  }

    render() {
      var pageNaviNum = [];
      for(var i=1;i<this.state.lastPage+1;i++) {
          pageNaviNum.push(i);
      }
      if(!this.state.seriallist){
        return <div/>
      };
        return (
          <div>
            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>시리얼번호</th>
                            <th>사용 여부</th>
                            <th>리셋</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.seriallist.map((val,i) => {
                            console.log("serial info", val)
                            return (
                                <tr key={i}>
                                    <td>{i}</td>
                                    <td>{val.SirialNumber}</td>
                                    {val.CreatePlayerCount > 0 ?
                                      <td className="text-success"> 사용 가능 </td>
                                      : <td className="text-muted">사용중</td>
                                    }
                                    <td>
                                        <button className="btn btn-outline-warning btn-sm" onClick={() => {this.handlerOnBtnReset(val.SirialNumber)}}>리셋</button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="row overflow-auto">
            { this.state.lastPage > 1 &&
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                { pageNaviNum.map((val,i) => {
                    return (
                      <>
                            {this.state.pageNow === val && <li key={i} className="page-item"><a className="page-link bg-primary text-white" onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>}
                            {this.state.pageNow !== val &&  <li key={i} className="page-item"><a className="page-link" onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>}
                      </>
                    )
                })}
                </ul>
            </nav>
            }
            </div>
            </div>
        );
    }
}

export default SerialList;
