/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceUser from "../service/ServiceUser";

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userlist : null,
            contentCount : 0,
            contentPerPage : 10,
            pageNow : 1,
            lastPage : 1,
        }
        this.handlerOnBtnActivate = this.handlerOnBtnActivate.bind(this);
        this.handlerOnBtnDetail = this.handlerOnBtnDetail.bind(this);
        this.handlerOnBtnEdit = this.handlerOnBtnEdit.bind(this);
        this.handlerOnBtnDel = this.handlerOnBtnDel.bind(this);
        this.handlerOnPage = this.handlerOnPage.bind(this);
        this.innerConstructor();
    }

    handlerOnBtnActivate(id) {
        console.log("function Activate called"+id);
    }

    handlerOnBtnDetail(id) {
        console.log("UID: "+id);
        this.props.callBackForDetail(id);
    }

    handlerOnBtnEdit(id) {
        this.props.callBackForUpdate(id);
        console.log("function Edit called"+id);
    }

    handlerOnBtnDel(id) {
        if(window.confirm("this action is not revoked. are you sure?")) {
            ServiceUser.svcDelUser(id)
            .then(response => {
                console.log(response);
                console.log("function Del called"+id);
                this.innerConstructor();
            })
            .catch(error => {
                console.log("UserList.js : handlerOnBtnDel : fetch list error "+error);
            });
        }
    }

    handlerOnPage(page) {
        this.setState({pageNow:page});
        ServiceUser.svcUserList(page,this.state.contentPerPage, this.props.filter)
        .then(response => {
            console.log(response);
            this.setState({userlist: response});
        })
        .catch(error => {
            console.log("UserList.js : innerConstructor : fetch list error "+error);
        });
}

    innerConstructor() {

        ServiceUser.svcUserCount(this.props.filter)
        .then(response => {
            this.setState({contentCount:response.count});
            this.setState({lastPage:Math.ceil(response.count/this.state.contentPerPage)});
            // first content count and fetch list

            ServiceUser.svcUserList(this.state.pageNow,this.state.contentPerPage, this.props.filter)
            .then(response => {
                this.setState({userlist: response});
            })
            .catch(error => {
                console.log("UserList.js : innerConstructor : fetch list error "+error);
            });
        })
        .catch(error => {
            console.log("database error "+error);
        });
    }

    render() {
        console.log("Filter: "+this.props.filter)
        var pageNaviNum = [];
        for(var i=1;i<this.state.lastPage+1;i++) {
            pageNaviNum.push(i);
        }
        if(!this.state.userlist){
          return <div/>
        };

        return (
          <div className="container-fluid">
            <div className="row table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>아이디</th>
                            <th>가입일</th>
                            {/* <th>나라</th> */}
                            <th>보기</th>
                            <th>수정/삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.userlist.map((val,i) => {

                            return (
                                <tr key={i}>
                                    <td>{i}</td>
                                    <td>{val.id}</td>
                                    <td>{val.CreateDate}</td>
                                    {/* <td>{val.Country}</td> */}
                                    <td>
                                        <button className="btn btn-outline-secondary btn-sm" onClick={() => {this.handlerOnBtnDetail(val.uid)}}>보기</button>
                                    </td>
                                    <td>
                                        <button className="btn btn-outline-primary btn-sm" onClick={() => {this.handlerOnBtnEdit(val.uid)}}>수정</button>&nbsp;
                                        <button className="btn btn-secondary btn-sm" onClick={() => {this.handlerOnBtnDel(val.uid)}}>삭제</button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </div>
            <div className="row overflow-auto">
            { this.state.lastPage > 1 &&
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                { pageNaviNum.map((val,i) => {
                    return (
                      <>
                            {this.state.pageNow === val && <li key={i} className="page-item"><a className="page-link bg-primary text-white" onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>}
                            {this.state.pageNow !== val &&  <li key={i} className="page-item"><a className="page-link" onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>}
                      </>
                    )
                })}
                </ul>
            </nav>
            }
            </div>
            </div>
        );
    }
}

export default UserList;
