/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import * as ServiceInquiries from "../service/ServiceInquiries";
import * as StringUtils from "../utils/StringUtils";

const checktxts = ["Check", "Uncheck"]
const rewardtxts = ["Not Rewarded", "Rewarded"]

class InquiryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inquirylist : null,
            contentCount : 0,
            contentPerPage : 10,
            pageNow : 1,
            lastPage : 1,
        }

        this.handlerOnBtnDetail = this.handlerOnBtnDetail.bind(this);
        this.handlerOnPage = this.handlerOnPage.bind(this);
        this.innerConstructor();
    }


    handlerOnBtnDetail(id) {
        console.log("UID: "+id);
        this.props.callBackForDetail(id);
    }


    handlerOnPage(page) {
        this.setState({pageNow:page});
        this.props.callBackForPageChange(page);
        ServiceInquiries.svcInquiryList(page,this.state.contentPerPage, this.props.filter)
        .then(response => {
            console.log(response);
            ServiceInquiries.svcInquiryChats('').then(_response => {
              for(var i=0; i<response.length; i++){
                for(var j=0; j<_response.length; j++){
                  if(response[i].dataid === _response[j].inquiry_id){
                    if(response[i].chats)
                        response[i].chats.push(_response[j])
                    else
                        response[i].chats = [_response[j]]
                  }
                }
              }
              this.setState({inquirylist: response});
            })
        })
        .catch(error => {
            console.log("inquirylist.js : innerConstructor : fetch list error "+error);
        });
    }

    innerConstructor() {
        ServiceInquiries.svcInquiryCount(this.props.filter)
        .then(response => {
            this.setState({contentCount:response.count});
            this.setState({lastPage:Math.ceil(response.count/this.state.contentPerPage)});
            // first content count and fetch list
            ServiceInquiries.svcInquiryList(this.state.pageNow,this.state.contentPerPage, this.props.filter)
            .then(response => {

                ServiceInquiries.svcInquiryChats('').then(_response => {
                  for(var i=0; i<response.length; i++){
                    for(var j=0; j<_response.length; j++){
                      if(response[i].dataid === _response[j].inquiry_id){
                        if(response[i].chats)
                            response[i].chats.push(_response[j])
                        else
                            response[i].chats = [_response[j]]
                      }
                    }
                  }
                  this.setState({inquirylist: response});
                })
            })
            .catch(error => {
                console.log("inquirylist.js : innerConstructor : fetch list error "+error);
            });
        })
        .catch(error => {
            console.log("database error "+error);
        });
    }

    render() {
        console.log("Filter: "+this.props.filter)
        var pageNaviNum = [];
        for(var i=1;i<this.state.lastPage+1;i++) {
            pageNaviNum.push(i);
        }
        if(!this.state.inquirylist){
          return <div/>
        };

        return (
          <div className="container-fluid">
            <div className="row table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>아이디</th>
                            <th>문의 유형</th>
                            <th>제목</th>
                            <th>문의일</th>
                            <th>채팅</th>
                            <th>답변 여부</th>
                            <th>답변일</th>
                            <th>보기</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.inquirylist.map((val,i) => {

                            return (
                                <tr key={i}>
                                    <td>{i}</td>
                                    <td>{val.id}</td>
                                    <td>{StringUtils.getInqType(val.type)}</td>
                                    <td>{val.title}</td>
                                    <td>{val.ask_date}</td>
                                    <td>
                                    {(val.chats && val.chats[val.chats.length - 1].uid !== 0) &&
                                      <div style={{backgroundColor: 'red', color:'#fff', width:"25px", height:"25px", textAlign:'center', borderRadius:10}}>
                                         N
                                      </div>
                                    }
                                    </td>
                                    {val.response &&
                                      <td className="text-success">답변</td>
                                    }
                                    {!val.response &&
                                      <td className="text-warning">미답변</td>
                                    }
                                    <td>{val.reply_date}</td>
                                    <td>
                                        <button className="btn btn-outline-secondary btn-sm" onClick={() => {this.handlerOnBtnDetail(val)}}>보기</button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </div>
            <div className="row overflow-auto">
            { this.state.lastPage > 1 &&
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                { pageNaviNum.map((val,i) => {
                    return (
                      <>
                            {this.state.pageNow === val && <li key={i} className="page-item"><a className="page-link bg-primary text-white" onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>}
                            {this.state.pageNow !== val &&  <li key={i} className="page-item"><a className="page-link" onClick={() => {this.handlerOnPage(val)}}>{val}</a></li>}
                      </>
                    )
                })}
                </ul>
            </nav>
            }
            </div>
            </div>
        );
    }
}

export default InquiryList;
